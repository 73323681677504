
import {
  onMounted,
  PropType,
  reactive,
  ref,
  SetupContext,
  toRaw,
  defineComponent,
  toRefs,
} from "vue";
import { CalendarOutlined, SmileOutlined } from '@ant-design/icons-vue';
import { CreateExaminerHubCmdForm } from "@/api-client/modal";
import { AddCoreDayByExaminerRequest, CoreDayStatusEnum, CoreDayRequestTimeSlotEnum, CoreDayTimeSlotEnum } from "@/api-client/client";
import { validateHubCapacity } from "@/utils/validate";
import { _Client } from "@/api-client";
import { message, Form, DatePicker, Button, Modal, Switch } from "ant-design-vue";
import dayjs, { Dayjs } from 'dayjs';
import { createVNode } from 'vue'; // 引入 h 函数
import { Item } from "ant-design-vue/lib/menu";

const useForm = Form.useForm;
interface AddExaminerModel {
  examinerId: string | undefined;
  examinerName: string | undefined;
  examinerNo: string | undefined;
}
export default defineComponent({
  props: {
    examinerList: {
      type: Array as PropType<AddExaminerModel[]>,
      default: () => [],
    },
  },
  components: {
    CalendarOutlined,
    SmileOutlined
  },
  setup(props: { [key: string]: any }, context: SetupContext) {
    const coreDayTimeSlot = CoreDayRequestTimeSlotEnum;
    const coreDayStatus = CoreDayStatusEnum;
    const examinerList = props.examinerList;
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }
    const columns = [
      {
        title: 'Time Slot',
        dataIndex: 'timeslot',
        key: 'timeslot',
        width: 200
      },
      {
        title: 'Core Day',
        dataIndex: 'coreDay',
        key: 'coreDay',
        width: 140
      },
      {
        title: 'Non-Core Day',
        dataIndex: 'nonCoreDay',
        key: 'nonCoreDay',
        width: 140
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: 500
      }
    ]

    class DataItem {
      timeSlot: CoreDayRequestTimeSlotEnum | undefined;
      coreDayStatus: CoreDayStatusEnum | undefined;
      nonCoreDayStatus: CoreDayStatusEnum | undefined;
      remark: string | undefined;
      isSelectedTimeSlot: boolean | undefined;
      coreDayNotSelected: boolean | undefined;
    }
    const tableList = ref<DataItem[]>([
      {
        timeSlot: CoreDayRequestTimeSlotEnum.FullDay,
        coreDayStatus: undefined,
        nonCoreDayStatus: undefined,
        remark: undefined,
        isSelectedTimeSlot: false,
        coreDayNotSelected: false,
      },
      {
        timeSlot: CoreDayRequestTimeSlotEnum.AM,
        coreDayStatus: undefined,
        nonCoreDayStatus: undefined,
        remark: undefined,
        isSelectedTimeSlot: false,
        coreDayNotSelected: false,
      },
      {
        timeSlot: CoreDayRequestTimeSlotEnum.PM,
        coreDayStatus: undefined,
        nonCoreDayStatus: undefined,
        remark: undefined,
        isSelectedTimeSlot: false,
        coreDayNotSelected: false,
      },
    ]);
    const selectedFullDay = ref(false);
    const saveLoading = ref<boolean>(false);
    const dates = ref<string[]>([]);
    const showDatePicker = ref(false);

    // 存储已获取的 Year & Month  避免频繁请求
    const yearMonthList: string[] = []
    const noneExaminingDateList = ref<Dayjs[]>([])
    const date_loading = ref(true)

    const getNoneExaminingDate = (year: number, month: number) => {
      if (dayjs().year() > year) {
        return;
      }

      if (dayjs().year() == year && dayjs().month() > month) {
        return;
      }

      const key = `${year}_${month}`
      if (yearMonthList.some(x => x == key)) {
        return;
      }

      date_loading.value = true

      yearMonthList.push(key)

      _Client.noneExaminingDateClient.getListByYearMonth(year, month).then(rep => {
        noneExaminingDateList.value = noneExaminingDateList.value.concat(rep.map(v => dayjs(v)))
      }).finally(() => {
        date_loading.value = false
      })

      console.log(year, month)
    }

    const disabledDate = (val: any) => {
      if (!val) {
        return false;
      }

      if (noneExaminingDateList.value.some(v => v.isSame(val, 'day'))) {
        return true;
      }

      return false;
    }

    const selectDate = (val: any) => {
      const date = val.format("YYYY-MM-DD")
      return dates.value.some(x => x == date);
    }
    const dateChange = (value: any) => {
      if (noneExaminingDateList.value.some(v => v.isSame(value, 'day'))) {
        return;
      }

      var index = dates.value.findIndex((x) => x == value.format("YYYY-MM-DD"))
      if (index == -1) {
        dates.value.push(value.format("YYYY-MM-DD"));
      }
      else {
        dates.value.splice(index, 1)
      }

    };

    // 处理关闭标签的事件
    const handleClose = (
      tagToRemove: { examinerId: string; examinerName: string },
      index: number
    ) => {
      // 从数组中移除标签
      examinerList.splice(index, 1);
    };
    async function AddCoreDay() {
      saveLoading.value = true;
      try {
        let isVerify = true;
        if (dates.value.length == 0) {
          message.warning('Please select Date.')
          isVerify = false;
        }
        else if(!tableList.value.some(x => x.isSelectedTimeSlot === true))
        {
          message.warning('Please check TimeSlot.')
          isVerify = false;
        }

        tableList.value.forEach((itemTable: DataItem) => {
          itemTable.coreDayNotSelected = (itemTable.isSelectedTimeSlot && !itemTable.coreDayStatus && !itemTable.nonCoreDayStatus);
        });

        if (tableList!.value!.some(x => x.coreDayNotSelected === true)) {
          isVerify = false;
        }
        if (!isVerify) {
          return;
        }

        let requestList: AddCoreDayByExaminerRequest[] = [];
        examinerList.forEach((examiner: AddExaminerModel) => {
          dates.value.map((date) => new Date(date)).forEach((date: Date) => {
            tableList.value.filter((item: DataItem) => item.isSelectedTimeSlot == true).forEach((item: DataItem) => {

              let dbCoreDayTimeSlotList: CoreDayTimeSlotEnum[] = [];
              // 拆分timeslot
              switch (item.timeSlot) {
                case CoreDayRequestTimeSlotEnum.AM:
                  dbCoreDayTimeSlotList.push(CoreDayTimeSlotEnum.AM1);
                  dbCoreDayTimeSlotList.push(CoreDayTimeSlotEnum.AM2);
                  break;
                case CoreDayRequestTimeSlotEnum.PM:
                  dbCoreDayTimeSlotList.push(CoreDayTimeSlotEnum.PM1);
                  dbCoreDayTimeSlotList.push(CoreDayTimeSlotEnum.PM2);
                  break;
                case CoreDayRequestTimeSlotEnum.FullDay:
                  dbCoreDayTimeSlotList.push(CoreDayTimeSlotEnum.AM1);
                  dbCoreDayTimeSlotList.push(CoreDayTimeSlotEnum.AM2);
                  dbCoreDayTimeSlotList.push(CoreDayTimeSlotEnum.PM1);
                  dbCoreDayTimeSlotList.push(CoreDayTimeSlotEnum.PM2);
                  break;
              }
              dbCoreDayTimeSlotList.forEach((dbCoreDayTimeSlot: CoreDayTimeSlotEnum) => {
                let request = new AddCoreDayByExaminerRequest();
                request.init({
                  examinerId: examiner.examinerId,
                  coreDayStatus: item.coreDayStatus ? item.coreDayStatus : item.nonCoreDayStatus,
                  date: date,
                  timeSlot: dbCoreDayTimeSlot,
                  remark: item.remark
                });
                requestList.push(request);

              })
            })
          });
        })
        let result = await _Client.coreDayManagementClient.addCoreDayByExaminer(requestList);
        if (result.isSuccess) {
          message.success("success");
          close(true);
        } else {

          if (result.hasDateExaminerList != null && result.hasDateExaminerList.length > 0) {
            let warning = "";
            result.hasDateExaminerList.forEach((hasDateExaminer) => {
              let repeatExaminerList = examinerList.filter((x: AddExaminerModel) => hasDateExaminer.examinerIdList?.includes(x.examinerId!));
              let date = hasDateExaminer.date.getFullYear() + '-' +
                ('0' + (hasDateExaminer.date.getMonth() + 1)).slice(-2) + '-' +
                ('0' + hasDateExaminer.date.getDate()).slice(-2);
              warning += repeatExaminerList.map((item: AddExaminerModel) => item.examinerNo).join(',') + " The examiner has already been scheduled " + date + ".<br/>";
            });
            Modal.warning({
              title: "",
              // 使用 function(h) 渲染 HTML 内容
              content: () => {
                return createVNode('div', { innerHTML: warning });
              },

              okText: "Confirm",
              width: 600 // 设置 Modal 的宽度为 600 像素
            })
            //message.warning(warning);
          }
          else {
            message.error("error");
          }
        }
      }
      finally {
        saveLoading.value = false;

      }
    }
    function handleSelectChange(record: DataItem) {
      if (!!record.coreDayStatus || !!record.nonCoreDayStatus) {
        record.coreDayNotSelected = false; // 设置为 false，表示已经选择了
      }
    }

    function handleChangeTimeSlot(record: DataItem) {
      if (!record.isSelectedTimeSlot) {
        record.coreDayNotSelected = false;
        record.nonCoreDayStatus = undefined;
        record.coreDayStatus = undefined;
      }

      // 选择了FullDay  清空其它项
      if (record.isSelectedTimeSlot && record.timeSlot == CoreDayRequestTimeSlotEnum.FullDay) {
        // 禁用控件
        selectedFullDay.value = true;
        tableList.value.forEach((item: DataItem) => {
          // 清空数据
          if (item.timeSlot != CoreDayRequestTimeSlotEnum.FullDay) {
            item.isSelectedTimeSlot = false;
            item.coreDayStatus = undefined;
            item.nonCoreDayStatus = undefined;
            item.remark = '';
            item.coreDayNotSelected = false;
          }
        })
      } else {
        selectedFullDay.value = false;

      }

    }

    return {
      close,
      examinerList,
      handleClose,
      AddCoreDay,
      dates,
      showDatePicker,
      dateChange,
      disabledDate,
      getNoneExaminingDate,
      selectDate,
      noneExaminingDateList,
      date_loading,
      saveLoading,
      columns,
      tableList,
      coreDayStatus,
      coreDayTimeSlot,
      handleChangeTimeSlot,
      selectedFullDay,
      handleSelectChange
    };
  }
});
