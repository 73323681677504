
import {
  reactive,
  ref,
  SetupContext,
  defineComponent,
  onMounted,
} from "vue";
import {
  CoreDayByExaminer,
  CoreDayByExaminerDto,
  CoreDayStatusEnum,
  CoreDayRequestTimeSlotEnum,
  SaveCoreDayByExaminerRequest,
  SearchCoreDayByExaminerRequest,
} from "@/api-client/client";
import { _Client } from "@/api-client";
import { Form, Switch, message } from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import { Item } from "ant-design-vue/lib/menu";
import { Rule } from "ant-design-vue/lib/form";

const useForm = Form.useForm;


const checkboxValue = ref([]);

const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth() + 1;
const coreDayTimeSlot = CoreDayRequestTimeSlotEnum;
const coreDayStatus = CoreDayStatusEnum;

export default defineComponent({
  props: {

    modifyExaminerId: { type: String },
    modifyExaminerName: { type: String },
    modifyExaminerNo: { type: String }
  },
  setup(props: { [key: string]: any }, context: SetupContext) {

    const columns = [
      {
        title: 'Date',
        dataIndex: 'dateStr',
        width: '13%',
        customRender: (dateText: any) => {
          const date = new Date(dateText.value);
          const options = { weekday: 'short' }; // 星期缩写
          const weekdayAbbreviation = date.toLocaleDateString('en-US', options);
          return `${dateText.value} (${weekdayAbbreviation})`;
        },
        // 合并单元格判断
        customCell: (record: any, index: any) => {
          // 如果是和上一行一样rowSpan就是0
          if (index > 0 && record.dateStr === tableSource!.value[index - 1].dateStr) {
            return { rowSpan: 0 };
          }
          // 如果是和上一行不一样rowSpan就是rowspan
          const rowspan = tableSource.value.filter((item: any) => item.dateStr === record.dateStr).length;
          return { rowSpan: rowspan };
        },
      },
      {
        title: 'Time Slot',
        dataIndex: 'timeslot',
        width: '13%',
      },
      {
        title: 'Core Day',
        dataIndex: 'coreDay',
        width: '10%',
      },
      {
        title: 'Non Core Day',
        dataIndex: 'nonCoreDay',
        width: '10%',
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        width: '15%',
      },
    ];

    const saveLoading = ref<boolean>(false);
    const formLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };
    const searchYear = ref<number>(currentYear);
    const searchMonth = ref<number>(currentMonth);
    const tableLoading = ref<boolean>(false)
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }
    const searchParames = ref<SearchCoreDayByExaminerRequest>();
    const tableSource = ref<CoreDayByExaminerDto[]>([])
    const examinerName = props.modifyExaminerName;
    const examinerNo = props.modifyExaminerNo;
    const noneExaminingDateList = ref<Dayjs[]>([])

    const isDistabled = (date: Date) => {
      return noneExaminingDateList.value.some(v => v.isSame(dayjs(date), 'day'))
    }

    /// 获取列表方法
    async function getList() {
      try {
        tableLoading.value = true
        searchParames.value = new SearchCoreDayByExaminerRequest();
        searchParames.value.examinerId = props.modifyExaminerId;
        searchParames.value.codyDayDate = new Date(searchYear.value + '-' + (searchMonth.value).toString().padStart(2, '0') + '-01');
        const dates = await _Client.noneExaminingDateClient.getListByYearMonth(searchYear.value, searchMonth.value);
        noneExaminingDateList.value = dates.map(v => dayjs(v))
        tableSource.value = await _Client.coreDayManagementClient.getCoreDayListByExaminer(searchParames.value!);
      }
      finally {
        tableLoading.value = false
      }
    }
    async function ModifyCoreDay() {
      saveLoading.value = true;
      try {
        let request = new SaveCoreDayByExaminerRequest();
        let coreDayByExaminerList: CoreDayByExaminer[] = [];

        tableSource!.value!.forEach((itemTable: CoreDayByExaminerDto) => {
          let coreDayInfo = new CoreDayByExaminer();
          coreDayInfo.date = new Date(itemTable.date);
          coreDayInfo.coreDayRequestTimeSlot = itemTable.coreDayRequestTimeSlot;
          coreDayInfo.coreDayStatus = !!itemTable.nonCoreDayType ? itemTable.nonCoreDayType : itemTable.coreDayType;
          coreDayInfo.remark = itemTable.remark;
          coreDayInfo.examinerId = props.modifyExaminerId;
          itemTable.coreDayNotSelected = (itemTable.isSelectedTimeSlot && !itemTable.nonCoreDayType && !itemTable.coreDayType);
          coreDayByExaminerList.push(coreDayInfo);
        });

        if (tableSource!.value!.some(x => x.coreDayNotSelected === true)) {
          saveLoading.value = false
          return;
        }

        request.requestList = coreDayByExaminerList;
        await _Client.coreDayManagementClient.saveCoreDayByExaminer(request);
        message.success("success");
        close(true);
      }
      finally {
        saveLoading.value = false
      }
    }

    const changeSearchParames = async () => {
      await getList()
    };
    async function resetSearchParams() {
      searchYear.value = currentYear;
      searchMonth.value = currentMonth;
      searchParames.value = new SearchCoreDayByExaminerRequest({
        examinerId: props.modifyExaminerId,
        codyDayDate: new Date(searchYear.value + '-' + (searchMonth.value).toString().padStart(2, '0') + '-01')
      })
      await getList()
    }

    function handleSelectChange(record: CoreDayByExaminerDto) {
      if (record.nonCoreDayType || record.coreDayType) {
        record.coreDayNotSelected = false; // 设置为 false，表示已经选择了
      }
    }
    function handleChangeTimeSlot(record: CoreDayByExaminerDto) {
      if (!record.isSelectedTimeSlot) {
        record.coreDayNotSelected = false;
        record.coreDayType = undefined;
        record.nonCoreDayType = undefined;
        return;
      }
      let currentDateTableList = tableSource.value?.filter((itemTable: CoreDayByExaminerDto) => itemTable.dateStr == record.dateStr);
      switch (record.coreDayRequestTimeSlot) {
        case CoreDayRequestTimeSlotEnum.FullDay:
          currentDateTableList?.forEach((currentDateTable: CoreDayByExaminerDto) => {
            if (currentDateTable.coreDayRequestTimeSlot != CoreDayRequestTimeSlotEnum.FullDay) {
              currentDateTable.isSelectedTimeSlot = false;
              record.coreDayNotSelected = false;
              currentDateTable.coreDayType = undefined;
              currentDateTable.nonCoreDayType = undefined;
            }
          });
          break;
        case CoreDayRequestTimeSlotEnum.AM:
        case CoreDayRequestTimeSlotEnum.PM:
          currentDateTableList?.forEach((currentDateTable: CoreDayByExaminerDto) => {
            if (currentDateTable.coreDayRequestTimeSlot == CoreDayRequestTimeSlotEnum.FullDay) {
              currentDateTable.isSelectedTimeSlot = false;
              record.coreDayNotSelected = false;
              currentDateTable.coreDayType = undefined;
              currentDateTable.nonCoreDayType = undefined;
            }
          });
          break;
      }
    }
    onMounted(async () => {
      await getList()
    })

    return {
      formLayout,
      tableLoading,
      close,
      columns,
      editingKey: '',
      tableSource,
      noneExaminingDateList,
      isDistabled,
      coreDayTimeSlot,
      checkboxValue,
      resetSearchParams,
      changeSearchParames,
      coreDayStatus,
      examinerName,
      examinerNo,
      ModifyCoreDay,
      searchYear,
      searchMonth,
      currentYear,
      saveLoading,
      handleSelectChange,
      handleChangeTimeSlot
    };
  }
});
